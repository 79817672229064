import { gql } from '@apollo/client';

export const customerAccessControlList = gql`
    query{
        customerAccessControlList{
            acl_code
        }
    }
`;

export const getStoreConfig = gql`
    query getStoreConfig(
        $path: String!
    ){
        getStoreConfig(
            path: $path
        )
    }
`;

export const isAccessAllowed = gql`
    query isAccessAllowed(
        $acl_code: String!
    ){
        isAccessAllowed(
            acl_code: $acl_code
        )
    }
`;

export const isAccessAllowedMultiple = gql`
    query isAccessAllowedMultiple($acl_code: [String]!) {
      isAccessAllowedMultiple(acl_code: $acl_code) {
        acl_code
        is_allowed
      }
    }
`;

export const getStoreLogo = gql`
query{
    getStoreLogo{
      favicon
      logo
    }
  }
`;

export const getCurrency = gql`
query {
    currency {
      base_currency_code
      base_currency_symbol
      default_display_currency_code
      default_display_currency_symbol
      available_currency_codes
      exchange_rates {
        currency_to
        rate
      }
    }
  }
  `;

export const csStores = gql`
query csStores(
  $hasChannel: QueryCsStoresHasChannelWhereHasConditions
  $first: Int!
  $page: Int
) {
  csStores(
    hasChannel: $hasChannel
    first: $first
    page: $page
  ) {
    data {
      channel {
        channel_name
        channel_code
      }
    }
  }
}
`;

export const storeConfigMenu = gql`
query storeConfigMenu {
  storeConfig{
      swiftoms_pickpack_wave_enable
      swiftoms_pickpack_batch_enable
      swiftoms_tada_enable
      swiftoms_enable_vendor_portal
  }
}
`;

export const katalisGetIntegration = gql`
query katalisGetIntegration {
  katalisGetIntegration {
    created_at
    integration_id
    status
    updated_at
  }
}
`;

export const katalisGetCreditBalance = gql`
query katalisGetCreditBalance {
  katalisGetCreditBalance
}
`;

export const getVendorIrisBalance = gql`
  {
    getVendorIrisBalance {
      balance
      balance_raw
    }
  }
`;

export default {
    customerAccessControlList,
    getStoreConfig,
    isAccessAllowed,
    isAccessAllowedMultiple,
    getCurrency,
    csStores,
    storeConfigMenu,
    katalisGetIntegration,
    katalisGetCreditBalance,
    getVendorIrisBalance,
};
